import React, { useState, useEffect } from "react";
import Allmaids from "./AllMaids";
import "./Allmaids.css";


export default function Package4Maids() {
  const [data, setData] = useState([]);
  const [maids] = useState("");


  useEffect(() => {


    fetch(
      "https://homecaredxb.me/api/v1/Maids?api-key=8c44w8kcocsw8gg0c4scwg4oogc8go4sgso4ccso&status[]=approved"
    )
      .then((response) => response.json())
      .then((result) => setData(result.response));
  }, []);

  const filteredData = data.filter(
    (a) => a.maid_type === "HC" && a.name.toLowerCase().includes(maids.toLowerCase())
  );

  return (
    <>
  
   
        <Allmaids data={filteredData} />
     
    </>
  );
}


// const baseURL ="http://localhost:2023/api/v2";
const baseURL = "https://amjadapi.com/api/v2"

// const baseURL ="https://homecare-api-api.onrender.com/api/v2"
 





export default baseURL;




const Hcprovide = () => {
 
  return (

    <div style={{ marginLeft: "20px", marginTop: '25px' , marginBottom : '1px'}}>
    <h2 class="text-2xl font-bold mb-4">Homecare provide:</h2>

    <ul class="list-inside pl-6 flex flex-wrap">
      <li class="mb-2 w-1/2">
        <span class="text-green-600">✔</span> Kamel pay card + Salary 
      </li>
      <li class="mb-2 w-1/2">
        <span class="text-green-600">✔</span> Medical
      </li>
      <li class="mb-2 w-1/2">
        <span class="text-green-600">✔</span> Resident visa and Emirate ID
      </li>
      <li class="mb-2 w-1/2">
        <span class="text-green-600">✔</span> Leave salary
      </li>
      <li class="mb-2 w-1/2">
        <span class="text-green-600">✔</span> Professional training with Certificate
      </li>
      <li class="mb-2 w-1/2">
        <span class="text-green-600">✔</span> Zero working papers
      </li>
      
    </ul>
    </div>
  
  );
};

export default Hcprovide;

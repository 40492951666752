import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomerDetails from "./CustomerDetails";
import { getUserProfileAction } from "../../redux/slices/users/usersSlice";
import PaymentDetails from "./PaymentDetaiils";

export default function CustomerProfile() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserProfileAction());
  }, [dispatch]);

  const { error, loading, profile } = useSelector((state) => state?.users);
  const monthly_subscription = profile?.user?.monthly_subscription;
  const quickpayment = profile?.user?.quickpayments;

  return (
    <div className="bg-gray-900 min-h-screen text-white">
      <div className="container mx-auto py-12">
        <h1 className="text-4xl font-bold text-center mb-8 animate__animated animate__fadeInDown">My Profile</h1>

        {loading ? (
          <div className="text-2xl text-center animate__animated animate__pulse">Loading...</div>
        ) : error ? (
          <div className="text-2xl text-red-500 text-center">{error?.message}</div>
        ) : monthly_subscription?.length <= 0 ? (
          <div className="text-2xl text-center">
            <CustomerDetails
              email={profile?.user?.email}
              dateJoined={new Date(profile?.user?.createdAt).toDateString()}
              fullName={profile?.user?.fullname}
            />
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="md:col-span-1">
              <CustomerDetails
                email={profile?.user?.email}
                dateJoined={new Date(profile?.user?.createdAt).toDateString()}
                fullName={profile?.user?.fullname}
              />
            </div>
          </div>
        )}

        <div className="mt-12 text-center">
          <h2 className="text-3xl font-semibold mb-4 animate__animated animate__fadeInUp">Payment Details</h2>
          <div className="space-y-6">
            {monthly_subscription?.map((subscription, index) => (
              <PaymentDetails key={index} name={"Subscription"} k={subscription} i={index} voucher={"Subscription"} />
            ))}
            {quickpayment?.map((singlePayment, index) => (
              <PaymentDetails key={index} name={"Payment"} k={singlePayment} i={index} voucher={"Payment"} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}



export default function MaidsDetails({ selectedMaid }) {

  return (
   
    <table className="w-full border-collapse border border-gray-300 rounded-lg overflow-hidden">
    <tbody>

      
      <tr>
        <td className="py-3 px-6 bg-gray-100 font-semibold border-b border-gray-300">Nationality:</td>
        <td className="py-3 px-6 bg-white font-semibold border-b border-gray-300">{selectedMaid.nationality}</td>
      </tr>
      <tr>
        <td className="py-3 px-6 bg-gray-100 font-semibold border-b border-gray-300">Age:</td>
        <td className="py-3 px-6 bg-white font-semibold border-b border-gray-300">{selectedMaid.age}</td>
      </tr>
      {/* <tr>
        <td className="py-3 px-6 bg-gray-100 font-semibold border-b border-gray-300">Experience:</td>
        <td className="py-3 px-6 bg-white font-semibold border-b border-gray-300">{selectedMaid.exp_country.join(', ')}</td>
      </tr>
      <tr>
        <td className="py-3 px-6 bg-gray-100 font-semibold">Years:</td>
        <td className="py-3 px-6 bg-white font-semibold">{selectedMaid.period_country.join(', ')}</td>
      </tr> */}
    </tbody>
  </table>
   
  );
}

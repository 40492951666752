import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const WhatsAppLink = styled.a`
  text-decoration: none;
  color: #25d366; /* WhatsApp green */
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }
`;

const WhatsAppButton = ({ maid }) => {
  const phoneNumber = '+97143439977';

  const handleClick = () => {
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      `Monthly selected worker name: ${maid.name} from: ${maid.nationality}`
    )}`;
    window.open(url, '_blank');
  };

  return (
    <WhatsAppLink onClick={handleClick}>
      <FontAwesomeIcon icon={faWhatsapp} style={{ marginRight: '5px' }} />
      WhatsApp
    </WhatsAppLink>
  );
};

export default WhatsAppButton;





const VideoMaid = ({ video }) => {

  return (
    <div  className=""> 
       
        
          <video src={video} controls></video>
   </div>
     
    
  );
};

export default VideoMaid;

import React, { useState , useEffect } from "react";
import "./Allmaids.css";
import MaidsNationalityList from "./MaidsNationalityList ";
import Hcprovide from "./static/Hcprovide";



export default function Allmaids({ data }) {
  const [showFilipino, setShowFilipino] = useState(true);
  const [showIndo, setShowIndo] = useState(false);
  const [showEthiopia, setShowEthiopia] = useState(false);
  const [showMyanmar, setShowMyanmar] = useState(false);
  const [showNebalAnnIndiaSerlinka, setShowNebalAnnIndiaSerlinka] = useState(false);
  const [showGhanaKenyaTanzania, setShowGhanaKenyaTanzania] = useState(false);
  const [setSelectedMaid] = useState(null);
 
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Define your mobile breakpoint here
  const isMobileView = windowWidth <= 700;

  const handleButtonForMyanmar = () => {
    setShowMyanmar(!showMyanmar);
    setShowIndo(false);
    setShowEthiopia(false);
    setShowFilipino(false);
    setShowNebalAnnIndiaSerlinka(false);
    setShowGhanaKenyaTanzania(false);
   
  };

  const handleButtonForFilipino = () => {
    setShowFilipino(!showFilipino);
    setShowIndo(false);
    setShowEthiopia(false);
    setShowMyanmar(false);
    setShowNebalAnnIndiaSerlinka(false);
    setShowGhanaKenyaTanzania(false);

  };

  const handleButtonForIndo = () => {
    setShowIndo(!showIndo);
    setShowFilipino(false);
    setShowEthiopia(false);
    setShowMyanmar(false);
    setShowNebalAnnIndiaSerlinka(false);
    setShowGhanaKenyaTanzania(false);
 
  };

  const handleButtonForEthiopian = () => {
    setShowEthiopia(!showEthiopia);
    setShowFilipino(false);
    setShowIndo(false);
    setShowMyanmar(false);
    setShowNebalAnnIndiaSerlinka(false);
    setShowGhanaKenyaTanzania(false);
  
  };

  const handleButtonForNebalAnnIndiaSerlinka = () => {
    setShowNebalAnnIndiaSerlinka(!showNebalAnnIndiaSerlinka);
    setShowFilipino(false);
    setShowIndo(false);
    setShowEthiopia(false);
    setShowMyanmar(false);
    setShowGhanaKenyaTanzania(false);
   
  };

  const handleButtonForGhanaKenyaTanzania = () => {
    setShowGhanaKenyaTanzania(!showGhanaKenyaTanzania);
    setShowFilipino(false);
    setShowIndo(false);
    setShowEthiopia(false);
    setShowMyanmar(false);
    setShowNebalAnnIndiaSerlinka(false);
  
  };


  const filipinoMaids = data
  .filter(function (maid) {
    return (
      maid.nationality.includes("Philippines") &&
      !maid?.maid_booked?.includes("Booked")&&
      !maid?.maid_booked?.includes("Empty")&&
      !maid?.maid_booked?.includes("Hold")&&
      !maid?.visa_status?.includes("Visa")           
    );
  })
  .sort(function (a, b) {
    const agencyA = a.agency.toUpperCase();
    const agencyB = b.agency.toUpperCase();

    if (agencyA === agencyB) {
      return 0;
    } else if (
      agencyA.includes("MING HWEE MANPOWER  RECRUITMENT INC/ MD")
    ) {
      return -1;
    } else if (
      agencyB.includes("MING HWEE MANPOWER  RECRUITMENT INC/ MD")
    ) {
      return 1;
    } else {
      return 0;
    }
  });

  const indonesianMaids = data.filter(
    (maid) =>
      maid.nationality.includes("Indonesia") &&
      !maid?.maid_booked?.includes("Booked")&&
      !maid?.maid_booked?.includes("Empty") &&
      !maid?.maid_booked?.includes("Hold")&&
      !maid?.visa_status?.includes("Visa")
  );

  const ethiopianMaids = data.filter(
    (maid) =>
      maid.nationality.includes("Ethiopia") &&
      !maid?.maid_booked?.includes("Booked")&&
      !maid?.maid_booked?.includes("Empty") &&
      !maid?.maid_booked?.includes("Hold")&&
      !maid?.visa_status?.includes("Visa")
  );

  const myanmarMaids = data.filter(
    (maid) =>
      maid.nationality.includes("Myanmar") && 
      !maid?.maid_booked?.includes("Booked")&&
      !maid?.maid_booked?.includes("Empty")&&
      !maid?.maid_booked?.includes("Hold")&&
      !maid?.visa_status?.includes("Visa")
  );

  const nebalAnnIndiaSerlinkaMaids = data.filter(
    (maid) => {
      const meetsNationalityCriteria =
        maid.nationality.includes('Nepal') ||
        maid.nationality.includes("Sri_Lanka") ||
        maid.nationality.includes("Indian");

      const NotAvilable = !maid?.maid_booked?.includes("Booked") && !maid?.maid_booked?.includes("Empty") && !maid?.maid_booked?.includes("Hold") && !maid?.visa_status?.includes("Visa") ;
      return meetsNationalityCriteria && NotAvilable;
    }
  );

  const ghanaKenyaTanzaniaMaids = data.filter(
    (maid) => {
      const meetsNationalityCriteria =
        maid.nationality.includes('Ghana') ||
        maid.nationality.includes("Kenya") ||
        maid.nationality.includes("Uganda")||
        maid.nationality.includes("Tanzania")

      const NotAvilable = !maid?.maid_booked?.includes("Booked") && !maid?.maid_booked?.includes("Empty") && !maid?.maid_booked?.includes("Hold") && !maid?.visa_status?.includes("Visa") ;
      return meetsNationalityCriteria && NotAvilable;
    }
  );



  const handleMaidClick = (maidName) => {
    setSelectedMaid(maidName);
  };

  


const renderNationalityButton = (nationality, count, showState, clickHandler) => (
  <div className="mb-2" key={nationality}>
    <button
      className={`btn ${showState ? "active" : ""}`}
      onClick={clickHandler}
    >
      <span className="nationality font-semibold">{nationality}</span>{" "}
      <span className="circle">{count}</span>
    </button>
  </div>
);

return (
  <div>
    <div
      className="bg-white rounded-lg w-full mx-auto my-5 md:my-10 p-5 container"
      style={{
        maxWidth: "800px",
        marginBottom: "7px",
      }}
    >
      <div className="mb-5 flex flex-wrap justify-center">
        {filipinoMaids.length > 0 && renderNationalityButton("Filipina", filipinoMaids.length, showFilipino, handleButtonForFilipino)}
        {ethiopianMaids.length > 0 && renderNationalityButton("Ethiopia", ethiopianMaids.length, showEthiopia, handleButtonForEthiopian)}
        {indonesianMaids.length > 0 && renderNationalityButton("Indonesian", indonesianMaids.length, showIndo, handleButtonForIndo)}
        {myanmarMaids.length > 0 && renderNationalityButton("Myanmar", myanmarMaids.length, showMyanmar, handleButtonForMyanmar)}
        {nebalAnnIndiaSerlinkaMaids.length > 0 && renderNationalityButton("Others", nebalAnnIndiaSerlinkaMaids.length, showNebalAnnIndiaSerlinka, handleButtonForNebalAnnIndiaSerlinka)}
        {ghanaKenyaTanzaniaMaids.length > 0 && renderNationalityButton("Africa", ghanaKenyaTanzaniaMaids.length, showGhanaKenyaTanzania, handleButtonForGhanaKenyaTanzania)}
      </div>

      <div className="md:ml-10 mt-4 md:mt-0">
        <p className="font-stylish text-lg">Monthly Contract</p>
        <img
          src="/signature.svg"
          alt="Signature"
          className="signature-image"
        />
      </div>

      <div style={{ marginBottom: "10px" }}>
        <Hcprovide />
      </div>
    </div>

  
   
   {!isMobileView  ?  (
   
    <div
      className="maid_list"
      style={{
       
        display: "flex",
        flexDirection: "column", // Stack items vertically
        alignItems: "center", // Center items horizontally
      }}
    >
      {showFilipino && filipinoMaids.length > 0 ? (
        <MaidsNationalityList data={filipinoMaids} handleMaidClick={handleMaidClick} />
      ) : showIndo && indonesianMaids.length > 0 ? (
        <MaidsNationalityList data={indonesianMaids} handleMaidClick={handleMaidClick} />
      ) : showEthiopia && ethiopianMaids.length > 0 ? (
        <MaidsNationalityList data={ethiopianMaids} handleMaidClick={handleMaidClick} />
      ) : showMyanmar && myanmarMaids.length > 0 ? (
        <MaidsNationalityList data={myanmarMaids} handleMaidClick={handleMaidClick} />
      ) : showNebalAnnIndiaSerlinka && nebalAnnIndiaSerlinkaMaids.length > 0 ? (
        <MaidsNationalityList data={nebalAnnIndiaSerlinkaMaids} handleMaidClick={handleMaidClick} />
      ) : showGhanaKenyaTanzania && ghanaKenyaTanzaniaMaids.length > 0 ? (
        <MaidsNationalityList data={ghanaKenyaTanzaniaMaids} handleMaidClick={handleMaidClick} />
      ) : (
        <p>Select Nationality.</p>
      )}
    </div> ) :

    
     //mobile view
     ( <div
      className="maid_list"
    
    >
      {showFilipino && filipinoMaids.length > 0 ? (
        <MaidsNationalityList data={filipinoMaids} handleMaidClick={handleMaidClick} />
      ) : showIndo && indonesianMaids.length > 0 ? (
        <MaidsNationalityList data={indonesianMaids} handleMaidClick={handleMaidClick} />
      ) : showEthiopia && ethiopianMaids.length > 0 ? (
        <MaidsNationalityList data={ethiopianMaids} handleMaidClick={handleMaidClick} />
      ) : showMyanmar && myanmarMaids.length > 0 ? (
        <MaidsNationalityList data={myanmarMaids} handleMaidClick={handleMaidClick} />
      ) : showNebalAnnIndiaSerlinka && nebalAnnIndiaSerlinkaMaids.length > 0 ? (
        <MaidsNationalityList data={nebalAnnIndiaSerlinkaMaids} handleMaidClick={handleMaidClick} />
      ) : showGhanaKenyaTanzania && ghanaKenyaTanzaniaMaids.length > 0 ? (
        <MaidsNationalityList data={ghanaKenyaTanzaniaMaids} handleMaidClick={handleMaidClick} />
      ) : (
        <p>Select Nationality.</p>
      )}
    </div> ) }
    
  </div>
);
}


import { BrowserRouter as Router, Routes, Route  } from 'react-router-dom';
import Package4Maids from './hooks/Package4Maids';
import RegisterAnewUser from './users/forms/RegisterAnewUser';
import LoginUsers from './users/forms/LoginUsers';
import Mainpage from './mainpage/Mainpage';
import UserProfile from './users/profile/UserProfile';
import NavBar from './Navbar/Navbar';
import Footer from './footer/Footer'
import QuickPayment from './users/quickpayment/QuickPaymentsUser';
import { useEffect } from 'react';






function App() {
  
  useEffect(() => {
    window.Intercom('boot', {
      app_id: "rtua6vqx", // Replace with your actual Intercom App ID
      // Add other configurations or user info if needed
    });

    return () => {
      window.Intercom('shutdown'); // Properly shutdown Intercom on unmount
    };
  }, []);

  
 

  return (
    <>
      <Router>
        <div className="bg-gray-100 min-h-screen font-sans">

          <NavBar/>   
          
            
        <div className="p-4">
            <Routes>
              <Route path="/" element={<Mainpage />} />
             
              <Route path="/package4" element={<Package4Maids />} />
              <Route path="/register" element={<RegisterAnewUser />} />
              <Route path="/login" element={<LoginUsers />} />
              <Route path="/customer-profile" element={<UserProfile />} />
              <Route path="/quickpayment" element={<QuickPayment/>} />
            </Routes>
         </div>
        </div>
         <Footer/>
      </Router>
    </>
  );
}


export default App;

import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faInstagram, faFacebook  } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';

const FooterContainer = styled.footer`
  background-color: #333;
  color: white;
  padding: 20px;
  text-align: center;
  position: absolute;
  bottom: -100;
  width: 100%;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  justify-content: center;

  a {
    margin: 10px;
  }
`;

const ContactNumbers = styled.div`
  margin-top: 20px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  p {
    margin: 5px;
  }

  .phone {
    font-weight: bold;
    font-size: 20px;
  }
`;


const CardImage = styled.img`
  width: 100%;
  height: auto;
  max-width: 20%;
  max-height: 50%;
  border-radius: 50px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;



function Footer() {

  const location = useLocation();

  if (location.pathname === '/package4') {
    return null; // Do not render anything if the path is '/package4'
  }
  return (
    <FooterContainer>
      <div className="footer-content">
        <SocialIcons>
          <a href="https://api.whatsapp.com/send?phone=%2B971123456789" target="_blank" rel="noreferrer noopener">
            <FontAwesomeIcon icon={faWhatsapp} size="2x" />
          </a>
          <a href="https://www.instagram.com/tadbeer_homecare_dubai/?igshid=YmMyMTA2M2Y%3D" rel="noreferrer noopener" target="_blank">
            <FontAwesomeIcon icon={faInstagram} size="2x" />
          </a>
          <a href="https://www.facebook.com/tadbeerhomecaredubaiuae" target="_blank" rel="noreferrer noopener">
            <FontAwesomeIcon icon={faFacebook} size="2x" />
          </a>
        </SocialIcons>

       
       
        <div style={{ position: 'relative', width: '100%', height: '350px', marginTop: '20px', overflow: 'hidden', borderRadius: '10px' }}>
            {/* Stylish Google Maps section */}
            <iframe
              title="Tadbeer Home Care Location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.9909996362115!2d55.248484499999994!3d25.199872299999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43cabc5c93d3%3A0xf527fe7c72155d27!2sTadbeer%20Home%20Care!5e0!3m2!1sen!2sus!4v1567650711551!5m2!1sen!2sus"
              style={{
                width: '100%',
                height: '100%',
                border: '2px solid #e0e0e0',
                borderRadius: '10px',
              }}
              allowFullScreen={false}
            />
             <CardImage
            src='/building.jpeg'
            alt='gg'
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
          </div>



     <ContactNumbers>
        <p className="phone">
          <FontAwesomeIcon icon={faPhone} className="icon" />
          English: 054 584 7131
        </p>
        <p className="phone">
          <FontAwesomeIcon icon={faPhone} className="icon" />
          Arabic : 054 586 7000
        </p>
        <p>
          <FontAwesomeIcon icon={faEnvelope} className="icon" />
          Email: info@tadbeerhomecare.ae
        </p>
      </ContactNumbers>
      </div>
    </FooterContainer>
  );
}

export default Footer;

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { MonthlyPaymentAction } from '../redux/slices/orders/ordersSlices';
import ComputerViewForMaidsList from './computerView/ComputerViewForMaidsList';
import PhoneViewForMaidsList from './mobileView/PhoneViewForMaidsList';

const MaidsList = ({ data }) => {
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const [selectedMaid, setSelectedMaid] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

 
  const isMobileView = windowWidth <= 700;

  const openTheVideo = (maid) => {
    setSelectedMaid(maid);
  };

  const closeTheVideo = () => {
    setSelectedMaid(null);
  };

  const handleHireClick = () => {
    if (selectedMaid) {
      const maidFromErp = selectedMaid.name;
      const amount = 7;
      if (!user) {
        
        window.location.href = "/register";
      } else {
        setIsLoading(true);
        dispatch(MonthlyPaymentAction({ maidFromErp, amount })).then(() => {
          setIsLoading(false);
        });
      }
    }
  };

  return (
    <>
      {isMobileView ? (
        //for Phone View
        <PhoneViewForMaidsList
        data={data}
        openTheVideo={openTheVideo}
        selectedMaid={selectedMaid}
        closeTheVideo={closeTheVideo}
        isLoading={isLoading}
        handleHireClick={handleHireClick}
        />
            
      ) : (      
        //for computer view
        <ComputerViewForMaidsList
        data={data}
        openTheVideo={openTheVideo}
        selectedMaid={selectedMaid}
        closeTheVideo={closeTheVideo}
        isLoading={isLoading}
        handleHireClick={handleHireClick}
        />
        
      )}
   </>
  );
};

export default MaidsList;


import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBroom, faArrowRight } from '@fortawesome/free-solid-svg-icons';


export default function Mainpage() {
  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <div className="max-w-md rounded-xl shadow-xl overflow-hidden bg-white"> {/* Updated to max-w-md */}
        <Link to={`/package4`} className="block">
          <div className="p-6 text-center">
            <FontAwesomeIcon icon={faBroom} size="3x" className="text-gray-500 700 mb-4" />
            <h2 className="text-4xl font-bold text-gray-500 mb-3">
              Monthly Maid Service
            </h2>
            <p className="text-black">
              Discover the art of clean with our minimalist approach to home care.
            </p>
          </div>
          <div className="px-8 pb-8">
            <img
              src="/package4.jpg"
              alt="Maid"
              className="w-full h-60 object-cover rounded-lg mb-5"
            />
            <h3 className="text-2xl font-semibold text-white mb-3">
              Simplify Your Life
            </h3>
            <button className="w-full py-3 bg-gradient-to-r from-blue-500 to-blue-700 text-white font-semibold rounded-lg shadow-md hover:bg-gradient-to-br focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-300 ease-in-out flex items-center justify-center">
              Get Started <FontAwesomeIcon icon={faArrowRight} size="lg" className="ml-2" />
            </button>
          </div>
        </Link>
      </div>
    </div>
  );
}


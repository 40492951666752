import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import LoadingComponent from "../../hooks/LoadingComp/LoadingComponent";
import { registerUserAction } from "../../redux/slices/users/usersSlice";



const RegisterAnewUser = () => {
  
  const dispatch = useDispatch();
  //dispatch
  const [formData, setFormData] = useState({
    fullname: "",
    phone: "",
    password: "",
    recaptchaValue:""
   
  });

  const [isVerified, setIsVerified] = useState(false);

  const onRecaptchaChange = (value) => {
    setFormData({ ...formData, recaptchaValue: value });
    if (value) {
      setIsVerified(true);
    }
  };

 

  //---Destructuring---
  const { fullname, phone, password ,address,email,city,recaptchaValue} = formData;
  //---onchange handler----
  const onChangeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //---onsubmit handler----
  const onSubmitHandler = (e) => {
    e.preventDefault();
    dispatch(  registerUserAction   ({ fullname, phone, password ,address,email,city,recaptchaValue}));
  };
  //select store data
  const { user, error, loading } = useSelector((state) => state?.users);
  //redirect
  useEffect(() => {
    if (user) {
      window.location.href = "/login";
    }
  }, [user]);
  return (
    <>
      <section className="relative overflow-x-hidden">
        <div className="container px-4 mx-auto">
          <div className="flex flex-wrap items-center">
            <div className="w-full lg:w-2/6 px-4 mb-12 lg:mb-0">
              <div className="py-20 text-center">
                <h3 className="mb-8 text-4xl md:text-5xl font-bold font-heading">
                  Signing up with Homecare Online
                </h3>
                {/* errr */}

                {error && (
                 <h3 className="text-red-500">{error.message}</h3>
                )}

                <p className="mb-10">After you have completed the registration process, you can book and hire your maid online</p>
                <form onSubmit={onSubmitHandler }>

                <ReCAPTCHA
                        sitekey="6LdzQ2UoAAAAAPz7-C0aJlFfekTSc8054H7vr2rv"
                        onChange={onRecaptchaChange}
                  />

                  <input
                    name="fullname"
                    value={fullname}
                    onChange={onChangeHandler}
                    className="w-full mb-4 px-12 py-6 border border-gray-200 focus:ring-blue-300 focus:border-blue-300 rounded-md"
                    type="text"
                    placeholder="Full Name"
                  />
                  <input
                    name="phone"
                    value={phone}
                    onChange={onChangeHandler}
                    className="w-full mb-4 px-12 py-6 border border-gray-200 focus:ring-blue-300 focus:border-blue-300 rounded-md"
                    type="text"
                    placeholder="Enter your phone number"
                  />
                  <input
                    name="password"
                    value={password}
                    onChange={onChangeHandler}
                    className="w-full mb-4 px-12 py-6 border border-gray-200 focus:ring-blue-300 focus:border-blue-300 rounded-md"
                    type="password"
                    placeholder="Enter your password"
                  />
                    <input
                    name="email"
                    value={email}
                    onChange={onChangeHandler}
                    className="w-full mb-4 px-12 py-6 border border-gray-200 focus:ring-blue-300 focus:border-blue-300 rounded-md"
                    type="email"
                    placeholder="Enter your email"
                  />
                    <input
                    name="city"
                    value={city}
                    onChange={onChangeHandler}
                    className="w-full mb-4 px-12 py-6 border border-gray-200 focus:ring-blue-300 focus:border-blue-300 rounded-md"
                    type="text"
                    placeholder="Enter your city"
                  />
                    <input
                    name="address"
                    value={address}
                    onChange={onChangeHandler}
                    className="w-full mb-4 px-12 py-6 border border-gray-200 focus:ring-blue-300 focus:border-blue-300 rounded-md"
                    type="enter your address"
                    placeholder="Enter your address"
                  />
              
                 

                 {loading ? (

                  <LoadingComponent/>

                  ) : (
                    <button 
                         disabled={!isVerified}
                         className="mt-12 md:mt-16 bg-blue-800 hover:bg-blue-900 text-white font-bold font-heading py-5 px-8 rounded-md uppercase">
                         Register
                    </button>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
        <div
          className="hidden lg:block lg:absolute top-0 bottom-0 right-0 lg:w-3/6 bg-center bg-cover bg-no-repeat"
          style={{
            backgroundImage:
              'url("")',
          }}
        />
      </section>
    </>
  );
};

export default RegisterAnewUser;

import axios from "axios";
import baseURL from "../../../utils/baseURL";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


const initialState = {
  orders: [],
  order: null,
  loading: false,
  error: null,
  isAdded: false,
  isUpdated: false,
  stats: null,
};


// Create Monthly Payment
export const MonthlyPaymentAction = createAsyncThunk(
  "quick/monthly",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { maidFromErp, amount } = payload;
      // Token
      const token = getState()?.users?.userAuth?.userInfo?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      // Request
      const { data } = await axios.post(
        `${baseURL}/orders/quick/monthly`,
        {
          maidFromErp,
          amount,
        },
        config
      );
      return window.open(data?.url);
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);


export const QuickPaymentAction = createAsyncThunk(
  "quick/payment",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { maidFromErp, amount } = payload;
      // Token
      const token = getState()?.users?.userAuth?.userInfo?.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      // Request
      const { data } = await axios.post(
        `${baseURL}/orders/quick/payment`,
        {
          maidFromErp,
          amount,
        },
        config
      );
      return window.open(data?.url);
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

// Slice
const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // MonthlyPaymentAction
    builder.addCase(MonthlyPaymentAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(MonthlyPaymentAction.fulfilled, (state, action) => {
      state.loading = false;
      state.order = action.payload;
      state.isAdded = true;
    });
    builder.addCase(MonthlyPaymentAction.rejected, (state, action) => {
      state.loading = false;
      state.order = null;
      state.isAdded = false;
      state.error = action.payload;
    });

      // QuickPaymentAction
      builder.addCase(QuickPaymentAction.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(QuickPaymentAction.fulfilled, (state, action) => {
        state.loading = false;
        state.order = action.payload;
        state.isAdded = true;
      });
      builder.addCase(QuickPaymentAction.rejected, (state, action) => {
        state.loading = false;
        state.order = null;
        state.isAdded = false;
        state.error = action.payload;
      });
  },
});

// Generate the reducer
const ordersReducer = ordersSlice.reducer;

export default ordersReducer;


import VideoMaid from '../ReusableComponent/VideoMaid'; 
import LoadingComponent from '../LoadingComp/LoadingComponent'; 
import WhatsAppButton from '../ReusableComponent/Whatsapp'; 
import MaidsDetails from '../ReusableComponent/MaidsDetailes'; 

const PhoneViewForMaidsList = ({ data, openTheVideo, selectedMaid, closeTheVideo, isLoading, handleHireClick }) => {
  return (
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
      {data.map((maid, i) => (
        <div key={i} className="mb-4" onClick={() => openTheVideo(maid)}>
          <div className="border bg-white brightness-125 border-gray-300 rounded-lg p-4 flex flex-col relative">
            <div className="relative w-full h-80 mb-4 overflow-hidden rounded-lg shadow-md">
              <img
                className="absolute inset-0 w-full h-full object-cover transition transform hover:scale-105 filter hover:brightness-125 hover:shadow-xl rounded-lg border-4 border-white"
                src={maid.img}
                alt={maid.name}
              />
              <div className="absolute bottom-0 left-0 m-2">
                <div className="play-button">
                  <div className="play-icon"></div>
                </div>
              </div>
            </div>
            <p className="text-gray-800 text-lg font-bold mb-2">
              <span className="text-silver">{maid.name}</span>
            </p>
            <ul className="list-disc list-inside">
              <li className="text-gray-600 text-lg font-semibold mb-2">
                Nationality: <span className="text-silver">{maid.nationality}</span>
              </li>
              <li className="text-gray-600 text-lg font-semibold">
                Age: <span className="text-silver ml-1">{maid.age}</span>
              </li>
              <li className="text-gray-600 text-lg font-semibold">
                Experience: <span className="text-silver ml-1">{maid.exp_country.join(', ')}</span>
              </li>
              <li className="text-gray-600 text-lg font-semibold">
                Years: <span className="text-silver ml-1">{maid.period_country.join(', ')}</span>
              </li>
            </ul>
          </div>
        </div>
      ))}

      {selectedMaid && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 flex items-center justify-center">
          <div className="max-w-screen-sm w-full bg-white p-4 rounded-lg shadow-md absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 animate-fade-in">
            <button
              className="absolute top-2 right-2 text-red text-2xl cursor-pointer"
              onClick={closeTheVideo}
            >
              x
            </button>
            <VideoMaid video={selectedMaid.video_link} />
            <div className="text-center mt-4">
              {isLoading ? (
                <LoadingComponent />
              ) : (
                <div className="flex flex-col items-center space-y-4">
                  <WhatsAppButton maid={selectedMaid} />
                  <button
                    className="text-white font-bold py-2 px-6 rounded-full bg-gradient-to-r from-orange-400 to-yellow-500 hover:shadow-xl transform hover:scale-105 transition duration-300 ease-in-out"
                    onClick={handleHireClick}
                  >
                    Hire {selectedMaid.name} & Pay
                  </button>
                  <MaidsDetails selectedMaid={selectedMaid} />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PhoneViewForMaidsList;

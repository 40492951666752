import { configureStore } from "@reduxjs/toolkit";

import ordersReducer from "../slices/orders/ordersSlices";

import usersReducer from "../slices/users/usersSlice";

//store
const store = configureStore({
  reducer: {
    users: usersReducer,
    orders: ordersReducer,
   
  },
});

export default store;

import VideoMaid from '../ReusableComponent/VideoMaid'; 
import LoadingComponent from '../LoadingComp/LoadingComponent'; 
import WhatsAppButton from '../ReusableComponent/Whatsapp'; 
import MaidsDetails from '../ReusableComponent/MaidsDetailes';

const ComputerViewForMaidsList = ({ data, openTheVideo, selectedMaid, closeTheVideo, isLoading, handleHireClick }) => {
    return (
        <div>
        {data.map((maid, i) => (
          <div
            key={i}
            className="button-container hover:scale-105 hover:shadow-xl duration-500 ease-in-out"
            style={{
              marginBottom: '7px',
              width: '900px',
              height: '200px',
              overflow: 'hidden',
              maxWidth: '800px',
              borderRadius: '10px',
            }}
          >
            <div
              style={{ backgroundColor: 'white', width: '100%' }}
              onClick={() => openTheVideo(maid)}
              className="border rounded-lg p-7 shadow-lg flex transform transition-transform filter brightness-125 duration-800 ease-in-out"
            >
              <div style={{ backgroundColor: 'white', marginBottom: '56px' }} className="flex-1">
                <div className="relative h-60 w-60 max-w-full border filter brightness-125">
                  <div className="absolute inset-0 flex items-center justify-center">
                    <img
                      src={maid.img}
                      alt={maid.name}
                      className="object-cover h-full w-full transition-transform transform"
                    />

                      <div className="absolute top-20 left-0 m-2">
                <div className="play-button">
                      <div className="play-icon"></div>
                    </div>
                </div>
              
                  </div>
                </div>
              </div>
              <div className="flex-1 pl-4" style={{ maxWidth: '300px' }}>
                <h2 className="text-xl font-semibold" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{maid.name}</h2>
                <p className="text-gray-600">Nationality: {maid.nationality}</p>
                <p className="text-gray-600">Age: {maid.age}</p>
                <p className="text-gray-600">Country-Exp: {maid.exp_country}</p>
              </div>
            </div>
          </div>
        ))}

        {selectedMaid && (
         
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-70">
            <div className="relative max-w-full">
              <div
                className="bg-white p-5 rounded-lg shadow-md w-80vw max-w-96 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 animate-fade-in"
              >
                <button
                  className="absolute top-5 right-2 text-red text-2xl cursor-pointer"
                  onClick={closeTheVideo}
                >
                  x
                </button>
                <VideoMaid video={selectedMaid.video_link} />
                <div style= {{}} className="text-center mt-4">
                  {isLoading ? (
                    <LoadingComponent />
                  ) : (
                    <>
                    <div className="flex flex-col items-center space-y-4">
                      <WhatsAppButton maid={selectedMaid} />
                      <button
                        className="text-white font-bold py-2 px-6 rounded-full bg-gradient-to-r from-orange-400 to-yellow-500 hover:shadow-xl transform hover:scale-105 transition duration-300 ease-in-out"
                        onClick={handleHireClick}
                      >
                        Hire {selectedMaid.name} & Pay
                      </button>
                      <MaidsDetails selectedMaid={selectedMaid} />
                    </div>
                  </>
                   
                  )}
                </div>
              </div>
            </div>
          </div>
         
        )}
      </div>
    );
  };
  
  export default ComputerViewForMaidsList;
  
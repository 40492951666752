import React from "react";
import { Link } from "react-router-dom";

export default function CustomerDetails({ email, dateJoined, fullName }) {
  return (
    <div className="bg-white p-8 rounded-lg shadow-md border border-gray-300">
      <h2 className="text-3xl font-semibold text-gray-800 mb-4">
        Welcome, <span className="text-blue-500">{fullName}</span>!
      </h2>
      <div className="flex flex-col space-y-4 text-gray-700">
        <div className="flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="w-5 h-5 mr-2"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
          </svg>
          <span className="font-medium">{email}</span>
        </div>

        <div className="flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="w-5 h-5 mr-2"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
          </svg>
          <span className="font-medium">Joined: {dateJoined}</span>
        </div>
      </div>
      <div className="mt-6">
        <Link
          to="/quickpayment"
          className="bg-blue-500 hover:bg-blue-600 text-white rounded-full px-6 py-3 transition duration-300 ease-in-out inline-block"
        >
          Make Quick Payment
        </Link>
      </div>
    </div>
  );
}

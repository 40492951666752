
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logoutAction } from '../redux/slices/users/usersSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

function NavBar() {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem('userInfo'));
  const isLoggedIn = user?.token ? true : false;

  // logout handler
  const logoutHandler = () => {
    dispatch(logoutAction());
    // reload
    window.location.reload();
  };

  return (
    <nav className="bg-gray-300 p-2 shadow-md">
      <div className="container mx-auto flex justify-between items-center">
        <div className="flex items-center space-x-4">
          <Link to="/" className="text-4xl font-extrabold text-gray-500 hover:text-gray-200 transition duration-300 ease-in-out">
           
            <img style={{ height: '40px', width: '40px' }} src='https://tadbeerhomecare.ae/frontend/img/homecare-logo.png' alt='gg'></img>
          </Link>

          {/* WhatsApp icon/link */}
         
          <a
         
            href="https://api.whatsapp.com/send?phone=%2B97143439977"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-500  hover:bg-green-500 hover:text-white hover:shadow-md hover:border hover:border-black  rounded-full p-3 flex items-center space-x-2 transition duration-300 ease-in-out"
          >
            <FontAwesomeIcon
              icon={faWhatsapp}
              className="text-2xl"
              style={{ color: 'green' }} // Set the initial color to green
            />
            <span className="text-lg text-gray-500">WhatsApp Us</span>
          </a>
        
        
        </div>

        {/* Navigation links (hidden on small screens) */}
        <ul className="hidden md:flex items-center space-x-4">
          <li>
            <Link
              to="/"
              className="text-gray-500 hover:text-gray-900 hover:underline transition duration-300 ease-in-out"
            >
              Home
            </Link>
          </li>
          {!isLoggedIn ? (
            <>
              <li>
                <Link
                  to="/register"
                  className="text-gray-500 hover:text-gray-900 hover:underline transition duration-300 ease-in-out"
                >
                  Register
                </Link>
              </li>
              <li>
                <Link
                  to="/login"
                  className="text-gray-500 hover:text-gray-900 hover:underline transition duration-300 ease-in-out"
                >
                  Login
                </Link>
              </li>
            </>
          ) : (
            <li className="flex items-center space-x-2">
              <Link
                to="/customer-profile"
                className="text-gray-500 hover:text-gray-900 hover:underline transition duration-300 ease-in-out"
              >
                My Profile
              </Link>
              <button
                onClick={logoutHandler}
                className="text-gray-500 hover:text-gray-900 hover:bg-red-500 hover:border hover:border-black rounded-full px-4 py-2 flex items-center space-x-1 transition duration-300 ease-in-out"
              >
                <span>Log Out</span>
                <FontAwesomeIcon icon="sign-out-alt" className="text-xl" />
              </button>
            </li>
          )}
        </ul>
      </div>

      {/* Navigation links as buttons on small screens */}
      <div className="md:hidden mt-4 flex justify-center">
        <Link
          to="/"
          className="bg-gray-500 hover:bg-gray-200 text-white hover:text-gray-500 hover:underline rounded-full px-4 py-2 m-2 transition duration-300 ease-in-out"
        >
          Home
        </Link>
        {!isLoggedIn ? (
          <>
            <Link
              to="/register"
              className="bg-gray-500 hover:bg-gray-200 text-white hover:text-gray-500 hover:underline rounded-full px-4 py-2 m-2 transition duration-300 ease-in-out"
            >
              Register
            </Link>
            <Link
              to="/login"
              className="bg-gray-500 hover:bg-gray-200 text-white hover:text-gray-500 hover:underline rounded-full px-4 py-2 m-2 transition duration-300 ease-in-out"
            >
              Login
            </Link>
          </>
        ) : (
         <>
          <Link
            to="/customer-profile"
            className="bg-gray-500 hover:bg-gray-200 text-white hover:text-gray-500 hover:underline rounded-full px-4 py-2 m-2 transition duration-300 ease-in-out"
          >
            My Profile
          </Link>

          <button
                onClick={logoutHandler}
                className="bg-gray-500 hover:bg-red-300 text-white hover:text-gray-500 hover:underline rounded-full px-4 py-2 m-2 transition duration-300 ease-in-out"
              >
                <span>Log Out</span>
                <FontAwesomeIcon icon="sign-out-alt" className="text-xl" />
           </button>
        </>
        )}
      </div>
    </nav>
  );
}

export default NavBar;

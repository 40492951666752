export default function PaymentDetails({ name, k, i, voucher }) {
  return (
    <div className="bg-gray-100 rounded-lg shadow-lg p-8">
      <div className="bg-gray-200 rounded-lg p-6 mb-6">
        <h1 className="text-3xl font-semibold text-gray-800">{name}</h1>
   
      </div>
     
      <div className="mb-4">
        <h2 className="text-xl font-semibold text-gray-800 mb-2">Payment Details:</h2>
        <p className="text-gray-600">
          Voucher Type: {voucher}
        </p>
        <p className="text-gray-600">
          Payment Number: {k?.paymentNumber}
        </p>
        <p className="text-gray-600">
          For Worker: {k?.maidFromErp}
        </p>
       
       
      </div>
      <div className="mb-6 flex justify-between items-center">
        <div>
          <h2 className="text-xl font-semibold text-gray-800">Total Amount:</h2>
          <p className="text-2xl text-green-700 font-semibold">AED {k?.amount}</p>
        </div>

        <div>
          <h2 className="text-xl font-semibold text-gray-800">Status:</h2>
          <p className="text-2xl text-green-700 font-semibold">{k?.paymentStatus}</p>
        </div>
        <div className="text-right">
          <p className="text-gray-600">Date: {new Date(k?.createdAt).toLocaleDateString()}</p>
        
        </div>
      </div>
      <div className="bg-gray-200 rounded-lg p-4 text-center text-gray-600 text-sm">
        Thank you for your business!
      </div>
    </div>
  );
}
